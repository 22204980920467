<template>
  <div v-if="isLoadingRequired == false">
    <CCard v-if="inventories && inventories.length > 0">
      <CCardHeader>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <h5>
            <b>Configure personal iCalendar link</b>
          </h5>
          <CButton color="primary" @click="generateLink">{{
            iItemCalendarLinkMultiple ? "Update configuration" : "Generate link"
          }}</CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <CSpinner
          v-if="isLoadingRequiredIcal"
          style="margin-left: 50%; z-index: 999999999"
          color="primary"
        />
        <div v-else>
          <CAlert :color="alertTypeIcal" v-if="messageIcal">
            {{ messageIcal }}</CAlert
          >
          <CRow>
            <CCol col="auto">
              <CInputRadio
                @change="selectInventories = false"
                label="All inventories"
                type="radio"
                name="types"
                :checked.sync="allInventories"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol col="auto">
              <CInputRadio
                @change="allInventories = false"
                label="Selected inventories"
                type="radio"
                name="types"
                :checked.sync="selectInventories"
              />
            </CCol>
          </CRow>
          <CRow v-if="selectInventories">
            <CCol>
              <treeselect
                v-model="selectedInventories"
                :flat="true"
                :defaultExpandLevel="Infinity"
                :isDefaultExpanded="true"
                :multiple="true"
                :options="inventories"
              />
            </CCol>
          </CRow>
          <CRow
            v-if="iItemCalendarLinkMultiple != null"
            style="margin-top: 20px"
          >
            <CCol>
              <CInput
                disabled
                label="Calendar Link"
                type="text"
                v-model="iItemCalendarLinkMultiple"
              >
              </CInput>
            </CCol>
            <CCol col="1" style="margin-top: 25px">
              <CButton
                @click="copyTextIcal()"
                color="link"
                title="Copy to clipboard"
              >
                <CIcon name="cil-save" />
              </CButton>
            </CCol>
          </CRow>
        </div>
      </CCardBody>
    </CCard>
    <CRow>
      <CCol>
        <CCard>
          <CCardBody style="min-height: 77vh">
            <CButton color="primary" @click="create()"
              ><CIcon name="cil-plus" /> Create New Inventory</CButton
            >
            <br />
            <br />
            <br />

            <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>
            <CDataTable
              :items="items"
              :fields="fields"
              :items-per-page="10"
              :clickableRows="true"
              @row-clicked="filterInventoryItems"
              hover
              pagination
            >
              <template #name="{ item }">
                <td>
                  <strong>{{ item.name }}</strong>
                </td>
              </template>
              <template #EDIT="{ item }">
                <td style="width: 15%">
                  <CButton color="primary" @click="edit(item.id)"
                    ><CIcon name="cil-pencil" /> Edit</CButton
                  >
                </td>
              </template>
              <template #DELETE="{ item }">
                <td style="width: 15%">
                  <CButton
                    color="danger"
                    @click="
                      warningModal = true;
                      inventory = item;
                    "
                    ><CIcon name="cil-trash" /> Delete</CButton
                  >
                </td>
              </template>
            </CDataTable>

            <CModal
              v-model="inventory.id"
              title="Are you sure to delete inventory?"
              color="danger"
              :show.sync="warningModal"
              @update:show="closeModal"
            >
              <CRow>
                <CCol col="12">
                  <CInput label="Name" v-model="inventory.name" disabled />
                </CCol>
              </CRow>
            </CModal>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol>
        <CCard>
          <CCardBody style="min-height: 77vh">
            <CAlert :color="alertTypeItem" v-if="messageItem">
              {{ messageItem }}</CAlert
            >
            <CButton color="primary" @click="addNewInventoryItem"
              ><CIcon name="cil-plus" /> Create New Inventory Item</CButton
            >
            <CButton
              color="primary"
              @click="refreshInventoryItemFilter"
              :disabled="!isRowClicked"
              style="margin-left: 1em"
              ><CIcon name="cilXCircle" /> Remove filtering</CButton
            >
            <br />
            <br />
            <br />

            <CModal
              v-model="inventoryItem.id"
              :title="inventoryItemModalTitle"
              :color="inventoryItemModalType"
              :show.sync="inventoryItemModal"
              @update:show="closeInventoryItemModal"
            >
              <div v-if="isDelete == false">
                <!-- <CRow style="margin-bottom: 1rem" v-if="isEdit == false">
              <CCol sm="3">
                <CInputRadio @change="selectFromExisting = false" label="Add New" type="radio" name="types"
                  :checked.sync="addNew" />
              </CCol>
              <CCol sm="5">
                <CInputRadio @change="addNew = false" label="Select From Existing" type="radio" name="types"
                  :checked.sync="selectFromExisting" />
              </CCol>
            </CRow> -->
                <div v-if="addNew == true">
                  <CRow>
                    <CCol col="12">
                      <CInput label="Name" v-model="inventoryItem.name" />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="12">
                      <CInput
                        label="Info Email"
                        v-model="
                          inventoryItem.responsibleEmailsSeparatedByComma
                        "
                      />
                    </CCol>
                  </CRow>

                  <CRow v-if="this.inventoryItemModalAction == 'add'">
                    <CCol col="12">
                      <label>Member of Inventory Groups</label>
                      <CMultiSelect
                        v-if="inventoryItems && inventoryItemsLoaded"
                        :multiple="true"
                        :selection="true"
                        v-model="inventoryItem.inventoryIds"
                        @update="appendValue($event)"
                        optionsEmptyPlaceholder="No options"
                        selectionType="text"
                        :options="parents"
                        :selected="inventoryItem.inventoryIds"
                      />
                      <!--  <CSelect label="Change parent of the item" :value.sync="inventoryItem.inventoryId" :plain="true"
                    :options="parents" placeholder="Select a parent">
                  </CSelect> -->
                    </CCol>
                  </CRow>
                  <CRow
                    style="margin-left: 15px"
                    v-if="this.inventoryItemModalType == 'info'"
                  >
                    <CRow v-if="iItemCalendarLink == null">
                      <CCol>
                        <label
                          >Click the below button to create your icalendar
                          link</label
                        >
                        <CRow>
                          <CButton
                            style="margin-left: 15px"
                            color="primary"
                            @click="generateInventoryItemIcal()"
                          >
                            <CIcon name="cil-plus" /> Generate your link
                          </CButton>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow v-if="iItemCalendarLink != null">
                      <CCol col="12">
                        <CInput
                          disabled
                          label="Calendar Link"
                          type="text"
                          v-model="iItemCalendarLink"
                        >
                        </CInput>
                      </CCol>
                      <CCol style="margin-top: 30px">
                        <CButton
                          @click="copyTextItem()"
                          color="link"
                          title="Copy to clipboard"
                        >
                          <CIcon name="cil-save" />
                        </CButton>
                      </CCol>
                    </CRow>
                  </CRow>
                </div>
                <div v-if="selectFromExisting">
                  <CCol col="12">
                    <CSelect
                      label="Select an inventory from a different group"
                      :value.sync="inventoryItem.id"
                      :plain="true"
                      :options="allInventoryItems"
                      placeholder="Select an inventory"
                    >
                    </CSelect>
                  </CCol>
                </div>
              </div>
            </CModal>

            <CDataTable
              :items="inventoryItemsFiltered"
              :fields="inventoryItemFields"
              pagination
              :items-per-page="10"
              hover
              v-if="inventory.id != emptyGuid && inventoryItems.length > 0"
            >
              <template #name="{ item }">
                <td @click="goToItem(item.id)">
                  <strong>{{ item.name }}</strong>
                </td>
              </template>
              <template #EDIT="{ item }">
                <td style="width: 15%">
                  <CButton color="primary" @click="editInventoryItem(item)">
                    <CIcon name="cil-pencil" /> Edit
                  </CButton>
                </td>
              </template>
              <template #DELETE="{ item }">
                <td style="width: 15%">
                  <CButton
                    color="danger"
                    @click="
                      inventoryItemModalTitle =
                        'Are You sure to delete inventory item ?';
                      inventoryItemModalAction = 'delete';
                      schemaId = item.id;
                      inventoryItemModal = true;
                      inventoryItemModalType = 'danger';
                      inventoryItem = item;
                      isDelete = true;
                    "
                  >
                    <CIcon name="cil-trash" /> Delete
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
  <CSpinner
    style="display: block; position: fixed; top: 50%; left: 50%"
    v-else
    color="primary"
  />
</template>

<script>
import axios from "axios";
import { nextTick } from "vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "Inventories",
  data: () => {
    return {
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      selectedInventories: null,
      inventories: [],
      // Delete
      inventory: [],
      warningModal: false,

      allInventories: true,
      selectInventories: false,

      // List
      items: [],
      fields: ["name", "EDIT", "DELETE"],

      //Alert
      message: null,
      alertType: "danger",

      messageItem: null,
      alertTypeItem: "danger",

      messageIcal: null,
      alertTypeIcal: "danger",

      //inventoryItem
      inventoryItems: [],
      inventoryItem: {
        id: "00000000-0000-0000-0000-000000000000",
        inventoryId: "00000000-0000-0000-0000-000000000000",
        name: null,
        responsibleEmailsSeparatedByComma: null,
      },
      parents: [],
      isDelete: false,
      isEdit: false,
      addNew: true,
      selectFromExisting: false,
      inventoryItemFields: ["name", "EDIT", "DELETE"],
      inventoryItemModalTitle: null,
      inventoryItemModal: false,
      inventoryItemModalAction: null,
      inventoryItemModalType: "danger",
      inventoryItemsLoaded: false,
      iItemCalendarLink: null,
      iItemCalendarLinkMultiple: null,
      inventoryItemsFiltered: null,
      isRowClicked: false,
      isLoadingRequired: false,
      isLoadingRequiredIcal: false,
    };
  },
  computed: {},
  components: {
    Treeselect,
  },
  methods: {
    closeModal(status, evt, accept) {
      if (accept) {
        this.delete(this.inventory.id);
      }
    },
    refreshInventoryItemFilter() {
      this.inventoryItemsFiltered = this.inventoryItems;
      this.isRowClicked = false;
    },
    edit(id) {
      const editLink = `inventories/create/${id.toString()}`;
      this.$router.push({ path: editLink });
    },
    filterInventoryItems(inventory) {
      this.inventoryItemsFiltered = this.inventoryItems.filter(
        (inventoryItem) => inventoryItem.inventoryIds.includes(inventory.id)
      );
      this.isRowClicked = true;
    },
    delete(id) {
      let self = this;
      axios
        .delete(`${this.$apiAdress}/v1/Inventory/${id}`)
        .then(function () {
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully deleted inventory.";
          self.getList();
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    create() {
      this.$router.push({ path: `inventories/create/${this.emptyGuid}` });
    },
    editInventoryItem(item) {
      this.inventoryItemsLoaded = false;
      this.inventoryItemModalTitle = "Edit inventory item";
      this.inventoryItemModal = true;
      this.inventoryItemModalAction = "add";
      this.inventoryItemModalType = "info";
      this.inventoryItem = item;
      this.isEdit = true;
      nextTick(() => {
        this.inventoryItemsLoaded = true;
      });
    },
    addNewInventoryItem() {
      this.inventoryItemsLoaded = false;
      this.inventoryItem = {
        id: "00000000-0000-0000-0000-000000000000",
        name: null,
        responsibleEmailsSeparatedByComma: null,
      };
      this.inventoryItemModal = true;
      this.inventoryItemModalType = "success";
      this.inventoryItemModalAction = "add";
      this.inventoryItemModalTitle = "Add new inventory item";
      nextTick(() => {
        this.inventoryItemsLoaded = true;
      });
    },
    appendValue(event) {
      this.inventoryItem.inventoryIds = event;
    },
    getList() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/Inventory`)
        .then(function (response) {
          self.items = response.data;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getParents() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/ListItem/InventoryList`)
        .then(function (response) {
          self.parents = response.data.map((parent) => {
            return {
              value: parent.value,
              text: parent.label,
            };
          });
          self.getInentoryItemList();
        })
        .catch(function (error) {
          self.alertTypeItem = "danger";
          self.messageItem = error;
          setTimeout(function () {
            self.messageItem = null;
          }, 10000);
        });
    },
    getIcalConfiguration() {
      let self = this;
      const user = localStorage.getItem("user");
      const userId =
        user && typeof user === "string" && user.length > 0
          ? JSON.parse(user).id
          : null;
      axios
        .get(`${this.$apiAdress}/v1/CalendarLink/${userId}`)
        .then(function (response) {
          self.selectedInventories = response.data;
          if (self.selectedInventories && self.selectedInventories.length > 0){
            self.iItemCalendarLinkMultiple = `${
              self.$apiAdress
            }/v1/ICalendar/multiple/${localStorage.getItem(
              "partner_id"
            )}/${userId}.ics`;
            if(self.selectedInventories.length == (self.extractUniqueIDs(self.inventories)).length)
              self.allInventories = true;
            else{
              self.allInventories = false;
              self.selectInventories = true;
            }
              
          }

        })
        .catch(function (error) {
          self.alertTypeIcal = "danger";
          self.messageItemIcal = error;
          setTimeout(function () {
            self.messageItemIcal = null;
          }, 10000);
        });
    },
    copyText() {
      navigator.clipboard.writeText(this.iCalendarLink);
      this.alertTypeItem = "success";
      this.messageItem = "Successfully copied.";
      setTimeout(function () {
        this.messageItem = null;
      }, 5000);
    },
    copyTextItem() {
      navigator.clipboard.writeText(this.iItemCalendarLink);
      this.alertTypeItem = "success";
      this.messageItem = "Successfully copied.";
      setTimeout(function () {
        this.messageItem = null;
      }, 5000);
    },
    copyTextIcal() {
      navigator.clipboard.writeText(this.iItemCalendarLinkMultiple);
      this.alertTypeIcal = "success";
      this.messageIcal = "Successfully copied.";
      setTimeout(function () {
        this.messageIcal = null;
      }, 5000);
    },
    generateLink() {
      let self = this;
      if(this.allInventories){
        this.selectedInventories = this.extractUniqueIDs(this.inventories);
      }
      if (!this.selectedInventories || this.selectedInventories.length == 0) {
        self.alertTypeIcal = "danger";
        setTimeout(function () {
          self.messageIcal = null;
        }, 10000);
        self.messageIcal = "Please select at least one inventory group / item";
        return;
      }
      this.isLoadingRequiredIcal = true;
      const user = localStorage.getItem("user");
      const userId =
        user && typeof user === "string" && user.length > 0
          ? JSON.parse(user).id
          : null;
      axios
        .post(
          `${this.$apiAdress}/v1/ICalendar/multiple/${userId}`,
          self.selectedInventories
        )
        .then(function (response) {
          self.iItemCalendarLinkMultiple = response.data;
          self.isLoadingRequiredIcal = false;
          self.alertTypeIcal = "success";
          setTimeout(function () {
            self.messageIcal = null;
          }, 10000);
          self.messageIcal = "Link generated successfully";
        })
        .catch(function (error) {
          self.alertTypeIcal = "danger";
          setTimeout(function () {
            self.messageIcal = null;
          }, 10000);
          self.messageIcal = error;
          self.isLoadingRequiredIcal = false;
        });
    },
    generateInventoryGroupIcal() {
      this.isLoadingRequired = true;
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/ICalendar/group/${this.inventory.id}`)
        .then(function (response) {
          self.iCalendarLink = response.data;
          self.isLoadingRequired = false;
        })
        .catch(function (error) {
          self.alertTypeItem = "danger";
          self.messageItem = error;
          self.isLoadingRequired = false;
          setTimeout(function () {
            self.messageItem = null;
          }, 10000);
        });
    },
    generateInventoryItemIcal() {
      this.isLoadingRequired = true;
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/ICalendar/item/${this.inventoryItem.id}`)
        .then(function (response) {
          self.iItemCalendarLink = response.data;
          self.isLoadingRequired = false;
        })
        .catch(function (error) {
          self.alertTypeItem = "danger";
          self.messageItem = error;
          self.isLoadingRequired = false;
          setTimeout(function () {
            self.messageItem = null;
          }, 10000);
        });
    },
    addInventoryItem() {
      let self = this;
      if (this.selectFromExisting == false) {
        if (!self.inventoryItem.name) {
          self.failed = true;
          self.messageItem = "Please enter all needed fields.";
          self.loading = false;
          self.alertTypeItem = "danger";
          setTimeout(function () {
            self.messageItem = null;
          }, 10000);
          return;
        }
      }
      self.inventoryItem.inventoryIds =
        self.inventoryItem.inventoryIds.join(",");
      if (this.selectFromExisting == false) {
        axios
          .post(`${this.$apiAdress}/v1/InventoryItem/`, self.inventoryItem)
          .then(function (response) {
            self.alertTypeItem = "success";
            self.messageItem = "Successfully updated inventory item.";
            setTimeout(function () {
              self.messageItem = null;
            }, 5000);
            self.getInentoryItemList();
            self.cleanInventoryItem();
          })
          .catch(function (error) {
            self.alertTypeItem = "danger";
            self.messageItem = error.response.data.message;
            setTimeout(function () {
              self.messageItem = null;
            }, 10000);
          });
      } else {
        self.inventoryItem.inventoryId = self.inventory.id;
        axios
          .post(
            `${this.$apiAdress}/v1/InventoryItem/createExisting`,
            self.inventoryItem
          )
          .then(function (response) {
            self.alertTypeItem = "success";
            self.messageItem = "Successfully updated inventory item.";
            setTimeout(function () {
              self.messageItem = null;
            }, 5000);
            self.getInentoryItemList();
            self.cleanInventoryItem();
          })
          .catch(function (error) {
            self.alertTypeItem = "danger";
            self.messageItem = error.response.data.message;
            setTimeout(function () {
              self.messageItem = null;
            }, 10000);
          });
      }
    },

    cleanInventoryItem() {
      let self = this;
      self.inventoryItem.id = "00000000-0000-0000-0000-000000000000";
      self.inventoryItem.name = null;
      self.iItemCalendarLink = null;
    },

    closeInventoryItemModal(status, evt, accept) {
      if (accept) {
        if (this.inventoryItemModalAction == "add") {
          this.addInventoryItem();
        } else if (this.inventoryItemModalAction == "delete") {
          this.deleteInventoryItem(this.inventoryItem.id);
        } else {
          this.addInventoryItem();
        }
        self.cleanInventoryItem();
      }
      this.iItemCalendarLink = null;
      this.isDelete = false;
      this.isEdit = false;
    },

    deleteInventoryItem(id) {
      let self = this;
      axios
        .delete(`${this.$apiAdress}/v1/InventoryItem/${id}`)
        .then(function (response) {
          self.alertTypeItem = "success";
          self.messageItem = "Successfully deleted inventory item.";
          setTimeout(function () {
            self.messageItem = null;
          }, 5000);
          self.cleanInventoryItem();
          self.getInentoryItemList();
        })
        .catch(function (error) {
          self.alertTypeItem = "danger";
          self.messageItem = error;
          setTimeout(function () {
            self.messageItem = null;
          }, 10000);
        });
    },
    extractUniqueIDs(arr) {
      let uniqueIDs = new Set();

      function extractIDs(obj) {
        uniqueIDs.add(obj.id);
        if (Array.isArray(obj.children)) {
          obj.children.forEach((child) => extractIDs(child));
        }
      }

      arr.forEach((item) => extractIDs(item));

      return Array.from(uniqueIDs);
    },
    getInentoryItemList() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/InventoryItem/List/all`)
        .then(function (response) {
          self.inventoryItems = response.data.map((value) => {
            return { ...value, inventoryIds: value.inventoryIds.split(",") };
          });
          self.inventories = [];
          self.parents.forEach((inventory) => {
            var children = [];
            var currentInventoryItems = self.inventoryItems.filter(
              (inventoryItem) => {
                return (
                  inventoryItem.inventoryIds.indexOf(inventory.value) != -1
                );
              }
            );
            currentInventoryItems.forEach((inventoryItem) => {
              children.push({
                id: inventoryItem.id,
                label: inventoryItem.name,
              });
            });
            self.inventories.push({
              id: inventory.value,
              label: inventory.text,
              children: children.length > 0 ? children : undefined,
            });
          });
          self.getIcalConfiguration();
          self.inventoryItemsFiltered = self.inventoryItems;
          self.inventoryItemsLoaded = true;
        })
        .catch(function (error) {
          self.alertTypeItem = "danger";
          self.messageItem = error;
          setTimeout(function () {
            self.messageItem = null;
          }, 10000);
        });
    },
  },
  mounted: function () {
    this.getList();
    //this.getInentoryItemList();
    this.getParents();
    //this.getIcalConfiguration();
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
